import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import ModalNotifications from "../linar/Modal/ModalNotifications";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell } from "react-feather";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, description, Icon, type }) {
  return (
    <ListItem divider component={Link} to="#">
      <ListItemAvatar sx={{ display: "flex", marginLeft: '5px' }}>
        <SvgIcon fontSize="small" sx={{
          backgroundColor: type === 1 ? 'green' : 'red',
          color: 'white',
          borderRadius: "100%",
          padding: "8px",
          width: "35px", 
          height: "35px"
        }} >
          <Icon />
        </SvgIcon>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    try {

      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
      const response = await axios.post('https://api.aluzion.ylinar.fr/api/notifications/getLast');
      setNotifications(response.data.data.lignes);
    } catch (error) {
      console.log(error)
    }
  };

  React.useEffect(() => {
    fetchNotifications();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={notifications.length}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {notifications.length} {t("New Notifications")}
          </Typography>
        </NotificationHeader>
        <List>
          {
            notifications.slice(0, 4).map((notification, index) => (
              <Box sx={{ ":hover": {backgroundColor: '#29384f'}}}>
                <Notification
                  key={index}
                  title={notification.title}
                  description={notification.description}
                  Icon={notification.icon === 1 ? Bell : WarningAmberIcon}
                  type={notification.icon}
                />
              </Box>
            ))}

        </List>
        <Box p={1} display="flex" justifyContent="center">
          <ModalNotifications /> {}
        </Box>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
