import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import de l'icône Close
import { useParams } from "react-router-dom";


const ListDevis = () => {
    const { id } = useParams();
    const [devis, setDevis] = useState([]);

    const fetchDevis = async () => {
        try {
            const response = await axios.get('https://api.aluzion.ylinar.fr/api/informations/devisprofile/' + id);
            if (response.data.status === 'success') {
                setDevis(response.data.data.devis);
            } else {
                console.error('Erreur lors de la récupération des devis: ', response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchDevis();
    }, []);

    return (
        <Box sx={{ paddingBottom: 6 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#ffffff'}} mb={5}>
                    Liste des Devis
                </Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 250,
                        '&::-webkit-scrollbar': {
                            width: '4px', // Largeur de la scrollbar
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888', // Couleur assombrie de la scrollbar
                            borderRadius: '10px', // Coins arrondis
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555', // Couleur plus foncée au survol
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#1B2635', // Couleur du fond de la scrollbar
                        },
                    }}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Devis</TableCell>
                                <TableCell align="center">Commande ID</TableCell>
                                <TableCell align="center">Client</TableCell>
                                <TableCell align="center">Référence</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Total HT</TableCell>
                                <TableCell align="center">Commercial</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devis.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover sx={{ '& > *': { borderBottom: 'unset' } }}
                                >
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.devisName}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">
                                        {row.commandeId === -1 ? (
                                            <CloseIcon sx={{ color: 'red' }} /> // Afficher la croix rouge si commandeId est -1
                                        ) : (
                                            row.commandeId
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.clientName}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.reference}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.description}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.totalHT.toFixed(2)} €</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.seller}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default ListDevis;