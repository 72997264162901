import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Projects from "./pages/pages/Projects";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";


// Page profile perso 

import PageProfile from "./pages/Profile/PageProfile"; 


// Dashboard components
const Default = async(() => import("./pages/dashboards"));
const Matiere = async(() => import("./pages/base/Matiere"));
const Article = async(() => import("./pages/base/Article"));
const Client = async(() => import("./pages/base/Client"));
const Fournisseur = async(() => import("./pages/base/Fournisseur"));
const Stock = async(() => import("./pages/base/Stock"));
const Carrousel = async(() => import("./pages/base/Carrousel"));
const CarrouselGestion = async(() => import("./pages/base/Carrousel/Gestion"));

const MatiereGestion = async(() => import("./pages/base/Matiere/Gestion"));
const ArticleGestion = async(() => import("./pages/base/Article/Gestion"));
const ClientGestion = async(() => import("./pages/base/Client/Gestion"));
const ClientContact = async(() => import("./pages/base/Client/Contact"));
const ClientAdress = async(() => import("./pages/base/Client/Adresse"));
const FournisseurGestion = async(() => import("./pages/base/Fournisseur/Gestion"));
const FournisseurMatiere = async(() => import("./pages/base/Fournisseur/Add"));
const FournisseurContact = async(() => import("./pages/base/Fournisseur/Contact"));
const Prospect = async(() => import("./pages/base/Prospect/Prospect"));
const ProspectGestion = async(() => import("./pages/base/Prospect/Gestion"));

const Opportunity = async(() => import("./pages/gestion/Opportunity"));
const Devis = async(() => import("./pages/gestion/Devis"));
const Commande = async(() => import("./pages/gestion/Commande"));
const Bon = async(() => import("./pages/gestion/Bon"));
const BonMultiple = async(() => import("./pages/gestion/Bon/Multiple"));

const Facture = async(() => import("./pages/gestion/Facture"));
const FactureAuto = async(() => import("./pages/gestion/FactureAuto"));

const Achat = async(() => import("./pages/gestion/Achat"));
const Container = async(() => import("./pages/gestion/Container"));
const ContainerGestion = async(() => import("./pages/gestion/Container/Gestion"));

const Reception = async(() => import("./pages/gestion/Reception"));
const ReceptionGestion = async(() => import("./pages/gestion/Reception/Gestion"));

const OpportunityGestion = async(() => import("./pages/gestion/Opportunity/Gestion"));
const OpportunityContact = async(() => import("./pages/gestion/Opportunity/Contact"));
const DevisGestion = async(() => import("./pages/gestion/Devis/Gestion"));
const CommandeGestion = async(() => import("./pages/gestion/Commande/Gestion"));
const FactureGestion = async(() => import("./pages/gestion/Facture/Gestion"));
const FactureAutoGestion = async(() => import("./pages/gestion/FactureAuto/Gestion"));
const AchatGestion = async(() => import("./pages/gestion/Achat/Gestion"));

const User = async(() => import("./pages/settings/Users"));
const TVA = async(() => import("./pages/settings/TVA"));
const Reglement = async(() => import("./pages/settings/Reglement"));
const LivraisonTerm = async(() => import("./pages/settings/LivraisonTerm"));
const PaiementTerm = async(() => import("./pages/settings/PaiementTerm"));
const Facturation = async(() => import("./pages/settings/Facturation"));
const Operation = async(() => import("./pages/settings/Operation"));
const Echeance = async(() => import("./pages/settings/Echeance"));
const Devise = async(() => import("./pages/settings/Devise"));
const Setting = async(() => import("./pages/settings/Setting"));
const Printer = async(() => import("./pages/settings/Printer"));

const Quadratus = async(() => import("./pages/gestion/Quadratus"));
const Previsionnel = async(() => import("./pages/gestion/Previsionnel"));


const Gamme = async(() => import("./pages/settings/Gamme"));
const GammeLine = async(() => import("./pages/settings/Gamme/Gestion"));

const ConditionnementUnit = async(() => import("./pages/settings/Conditionnement/Unite"));
const ConditionnementType = async(() => import("./pages/settings/Conditionnement/Type"));
const ArticleFamily = async(() => import("./pages/settings/Famille/Article"));
const MatiereFamily = async(() => import("./pages/settings/Famille/Matiere"));
const Activity = async(() => import("./pages/settings/Activity"));



const DevisData = async(() => import("./pages/export/Devis"));
const CommandeData = async(() => import("./pages/export/Commande"));
const FactureData = async(() => import("./pages/export/Facture"));
const StockData = async(() => import("./pages/export/Stock"));
const AchatData = async(() => import("./pages/export/Achat"));
const FactureStats = async(() => import("./pages/export/StatsCommandes"));


const Informations = async(() => import("./pages/informations"));



// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));
const ApexCharts = async(() => import("./pages/charts/ApexCharts"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element:
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>,
    children: [
      {
        path: "",
        element: <Default />,
      },
      {
        path: "profile/:id", 
        element: <PageProfile/>,
      },
    ],
  },
  {
    path: "gestion",
    element:
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>,
    children: [
      {
        path: "opportunity",
        element: <Opportunity />,
      },
      {
        path: "opportunity/edit/:id",
        element: <OpportunityGestion />,
      },
      {
        path: "opportunity/contact/:id",
        element: <OpportunityContact />,
      },
      {
        path: "devis",
        element: <Devis />,
      },
      {
        path: "devis/edit/:id",
        element: <DevisGestion />,
      },
      {
        path: "commande",
        element: <Commande />,
      },
      {
        path: "commande/edit/:id",
        element: <CommandeGestion />,
      },
      {
        path: "bon",
        element: <Bon />,
      },
      {
        path: "bon/multiple",
        element: <BonMultiple />,
      },
      {
        path: "facture",
        element: <Facture />,
      },
      {
        path: "facture/auto",
        element: <FactureAuto />,
      },
      {
        path: "facture/auto/edit/:id",
        element: <FactureAutoGestion />,
      },
      {
        path: "facture/edit/:id",
        element: <FactureGestion />,
      },
      {
        path: "achat",
        element: <Achat />,
      },
      {
        path: "achat/edit/:id",
        element: <AchatGestion />,
      },
      {
        path: "quadratus",
        element: <Quadratus />
      },
      {
        path: "previsionnel",
        element: <Previsionnel />
      },

      {
        path: "container",
        element: <Container />,
      },
      {
        path: "container/edit/:id",
        element: <ContainerGestion />,
      },

      {
        path: "reception",
        element: <Reception />,
      },
      {
        path: "reception/edit/:id",
        element: <ReceptionGestion />,
      },

    ],
  },
  {
    path: "file",
    element:
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>,
    children: [
      {
        path: "matiere",
        element: <Matiere />,
      },
      {
        path: "matiere/gestion/",
        element: <MatiereGestion />
      },
      {
        path: "matiere/gestion/:id",
        element: <MatiereGestion />
      },
      {
        path: "stock",
        element: <Stock />,
      },
      {
        path: "carrousel",
        element: <Carrousel />,
      },
      {
        path: "carrousel/:id",
        element: <CarrouselGestion />,
      },
      {
        path: "article",
        element: <Article />,
      },
      {
        path: "article/gestion/",
        element: <ArticleGestion />
      },
      {
        path: "article/gestion/:id",
        element: <ArticleGestion />
      },
      {
        path: "client",
        element: <Client />,
      },
      {
        path: "prospect", 
        element: <Prospect/>,
      },
      {
        path: "prospect/gestion/", 
        element: <ProspectGestion/>,
      },
      {
        path: "prospect/gestion/:id",
        element: <ProspectGestion />
      },
      {
        path: "client/gestion/",
        element: <ClientGestion />
      },
      {
        path: "client/gestion/:id",
        element: <ClientGestion />
      },
      {
        path: "client/contact/:id",
        element: <ClientContact />
      },
      {
        path: "client/adress/:id",
        element: <ClientAdress />
      },
      {
        path: "fournisseur",
        element: <Fournisseur />,
      },
      {
        path: "fournisseur/contact/:id",
        element: <FournisseurContact />
      },
      {
        path: "fournisseur/gestion/",
        element: <FournisseurGestion />
      },
      {
        path: "fournisseur/gestion/:id",
        element: <FournisseurGestion />
      },
      {
        path: "fournisseur/add/:id",
        element: <FournisseurMatiere />
      },
    ],
  },
  {
    path: "settings",
    element:
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>,
    children: [
      {
        path: "users",
        element: <User />,
      },
      {
        path: "tax",
        element: <TVA />,
      },
      {
        path: "modereglement",
        element: <Reglement />,
      },
      {
        path: "livraisonTerm",
        element: <LivraisonTerm />,
      },
      {
        path: "paiementTerm",
        element: <PaiementTerm />,
      },
      {
        path: "typefacturation",
        element: <Facturation />,
      },
      {
        path: "operation",
        element: <Operation />,
      },
      {
        path: "echeance",
        element: <Echeance />,
      },
      {
        path: "devise",
        element: <Devise />,
      },
      {
        path: "setting",
        element: <Setting />,
      },
      {
        path: "gamme",
        element: <Gamme />,
      },
      {
        path: "gamme/edit/:id",
        element: <GammeLine />,
      },
      {
        path: "conditionnement/unit",
        element: <ConditionnementUnit />,
      },
      {
        path: "conditionnement/type",
        element: <ConditionnementType />,
      },
      {
        path: "family/article",
        element: <ArticleFamily />,
      },
      {
        path: "family/matiere",
        element: <MatiereFamily />,
      },
      {
        path: "activity",
        element: <Activity />,
      },
      {
        path: "printer",
        element: <Printer />,
      },
    ],
  },

  {
    path: "export",
    element: <DashboardLayout />,
    children: [
      {
        path: "devis",
        element: <DevisData />,
      },
      {
        path: "commande",
        element: <CommandeData />,
      },
      {
        path: "facture",
        element: <FactureData />,
      },
      {
        path: "achat",
        element: <AchatData />,
      },
      {
        path: "stock",
        element: <StockData />,
      },
      {
        path: "statscommandes",
        element: <FactureStats />,
      },
    ],
  },
  {
    path: "informations",
    element: <DashboardLayout />,
    children: [
      {
        path: "client/:id",
        element: <Informations />,
      },
    ],
  },

  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "components",
    element: <DashboardLayout />,
    children: [
      {
        path: "accordion",
        element: <Accordion />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "chips",
        element: <Chips />,
      },
      {
        path: "dialogs",
        element: <Dialogs />,
      },
      {
        path: "lists",
        element: <Lists />,
      },
      {
        path: "menus",
        element: <Menus />,
      },
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "progress",
        element: <Progress />,
      },
      {
        path: "snackbars",
        element: <Snackbars />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
    ],
  },
  {
    path: "forms",
    element: <DashboardLayout />,
    children: [
      {
        path: "pickers",
        element: <Pickers />,
      },
      {
        path: "selection-controls",
        element: <SelectionCtrls />,
      },
      {
        path: "selects",
        element: <Selects />,
      },
      {
        path: "text-fields",
        element: <TextFields />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
    ],
  },
  {
    path: "tables",
    element: <DashboardLayout />,
    children: [
      {
        path: "simple-table",
        element: <SimpleTable />,
      },
      {
        path: "advanced-table",
        element: <AdvancedTable />,
      },
      {
        path: "data-grid",
        element: <DataGrid />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "material-icons",
        element: <MaterialIcons />,
      },
      {
        path: "feather-icons",
        element: <FeatherIcons />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "chartjs",
        element: <Chartjs />,
      },
      {
        path: "apexcharts",
        element: <ApexCharts />,
      },
    ],
  },
  {
    path: "maps",
    element: <DashboardLayout />,
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
